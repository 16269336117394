<template>
  <div>
    <!-- 表格组件 -->
    <el-table :data="paginatedData" style="width: 100%;margin-top: 50px">
      <!-- 定义表头 -->
      <el-table-column prop="phone" label="Mobile" width="180" />
      <el-table-column prop="calldate" label="call date" />
      <el-table-column prop="is_handle" label="status" />
      <!-- 添加操作按钮列 -->
      <el-table-column label="Action" width="120">
        <template #default="scope">
          <el-button size="mini" @click="loanDetails(scope.row)">Loan Details</el-button>
          <el-button size="mini" @click="handleProcessed(scope.row)">Handle Processed</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页组件 -->
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <!-- 弹出层 -->
    <el-dialog
      v-model="dialogVisible"
      title="Loan Detail"
      width="50%"
      @close="handleClose"
    >
      <!-- 详情表格组件 -->
      <el-table :data="loanDetailData" style="margin-top: 20px;width: 100%">
        <!-- 定义表头 -->
        <el-table-column prop="login_name" label="Mobile" width="180" />
        <el-table-column prop="user_name" label="User Name" />
        <el-table-column prop="name" label="Project" />
        <el-table-column prop="loan_amt" label="LoanAmt (PHP)" />
        <el-table-column prop="due_time" label="DueTime" />
        <el-table-column prop="fact_no_repay_amt" label="Over Amount(PHP)" />
        <el-table-column prop="yqts" label="Over Days" />
        <el-table-column prop="loan_nbr" label="loan_nbr" />
        <el-table-column prop="hkm" label="Repayment Code" />
        <el-table-column prop="zqm" label="Extension Code" />
        <el-table-column label="Action" width="120">
          <template #default="scope">
            <!-- 这里可以根据需要添加多个按钮，这里只展示一个示例按钮 -->
            <el-button size="mini" @click="loanInfo(scope.row)">Remind</el-button>
          </template>
        </el-table-column>
        <!-- 可以根据需要添加更多的列 -->
      </el-table>

      <!-- 关闭按钮 -->
      <template #footer>
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false">OK</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import apiRequest from '../../utils/api-request'
export default {
  mixins: [apiRequest],
  data() {
    return {
      dialogVisible: false,
      showLoanDetail: false, // 控制新列表显示的布尔值
      loanDetailData: [], // 存储新列表的数据
      token: '',
      listData: [],
      // 分页相关
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总数据量，可以从后端API获取，或者基于listData计算
      // 用于显示在表格中的分页后数据
      paginatedData: []
    }
  },
  created() {
    const token = localStorage.getItem('userToken')
    if (token === '') {
      this.$router.push({ name: 'login' })
    }
    this.token = token
    const params = {
      token: token
    }
    this.apiValidateToken(params).then((response) => {
      if (response.success) {
        const userType = localStorage.getItem('userType')
        if (userType === '1') {
          this.$router.push({ name: 'home' })
        }
        if (userType === '2') {
          this.$router.push({ name: 'remind' })
        }
      } else {
        this.$router.push({ name: 'login' })
      }
    })
    this.apiOverdueConnection(params).then(response => {
      this.listData = response.data
      this.total = response.data.length
      this.fetchData()
    }).catch(error => {
      console.error('Failed to fetch data:', error)
    })
  },
  methods: {
    // 分页数据获取
    fetchData() {
      const start = (this.currentPage - 1) * this.pageSize
      const end = start + this.pageSize
      this.paginatedData = this.listData.slice(start, end)
    },
    // 处理每页条数改变
    handleSizeChange(val) {
      this.pageSize = val
      this.fetchData()
    },
    // 处理当前页改变
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetchData()
    },
    // 手机号对应的所有平台逾期数据
    loanDetails(row) {
      console.log('loanDetails:', row)
      this.dialogVisible = true // 显示弹出层
      const mikangyunAccount = localStorage.getItem('mikangyunAccount')
      const params = {
        phone: row.phone,
        extension: mikangyunAccount
      }
      this.apiCollectionInfo(params).then((response) => {
        this.loanDetailData = response.msg
      })
    },
    handleProcessed(row) {
      // 处理 processed 的逻辑
      console.log('Handle Processed:', row)
    }
  }
}
</script>

<style scoped>

</style>
